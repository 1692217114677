
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import { CountryData } from '@/utils/general'

@Component
  export default class CountrySelector extends Vue {
  @Prop({ default: null }) readonly value!: CountryData | null;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: () => [] }) readonly countryData!: CountryData[];

  menu = false;
  search = '';

  selectedCountry: CountryData | null = null;

  mounted () {
    this.selectedCountry = this.countryData[0]
    if (this.value) {
      this.selectedCountry = this.value
    }

    this.$emit('input', this.selectedCountry)
  }

  @Watch('value', { immediate: true })
  onValueChange (newVal: CountryData | null) {
    if (newVal) {
      this.selectedCountry = newVal
    }
  }

  get filteredCountries () {
    if (this.search.trim() === '') {
      return this.countryData
    }
    const searchTerm = this.removeDiacritics(this.search.trim().toLowerCase())

    return this.countryData.filter(country => {
      const countryName = this.removeDiacritics(country.name.toLowerCase())
      const countryCode = country.code.toLowerCase()
      const countryPrefix = country.prefix

      return (
        countryName.includes(searchTerm) ||
        countryCode.includes(searchTerm) ||
        countryPrefix.includes(searchTerm)
      )
    })
  }

  @Emit('input')
  selectCountry (country: CountryData) {
    this.selectedCountry = country
    this.menu = false
    return country
  }

  @Watch('menu')
  onMenuChange (val: boolean) {
    if (!val) {
      this.search = ''
    }
  }

  removeDiacritics (text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
  }
